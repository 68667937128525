import { Controller } from "stimulus";
import { ViewportEntranceObserver } from "src/viewport_entrance_observer"
import { getPlaceholderImageURL } from "src/utils"

const viewportEntranceObserver = new ViewportEntranceObserver("500px")
const placeholderImageURL = getPlaceholderImageURL(0, 0, 0, 0.04)
const processedImageURLs = new Set

export default class extends Controller {
  static get shouldLoad() {
    const isSafari = /^((?!Chrome|Android).)*(Safari|iOS)/i.test(navigator.userAgent)
    return !isSafari
  }

  connect() {
    if (this.shouldShowPlaceholderImage) {
      this.showPlaceholderImage()
      this.observeViewportEntrance()
    }
  }

  disconnect() {
    this.stopObservingViewportEntrance()
  }

  get shouldShowPlaceholderImage() {
    return !processedImageURLs.has(this.element.src)
  }

  get isShowingPlaceholderImage() {
    return this.data.has("src")
  }

  observeViewportEntrance() {
    viewportEntranceObserver.observe(this.element, this)
  }

  stopObservingViewportEntrance() {
    viewportEntranceObserver.stopObserving(this.element)
  }

  elementWillEnterViewport() {
    this.showOriginalImage()
  }

  showPlaceholderImage() {
    if (!this.isShowingPlaceholderImage) {
      this.stashAttribute("srcset")
      this.stashAttribute("src")
      this.element.src = placeholderImageURL
    }
  }

  showOriginalImage() {
    if (this.isShowingPlaceholderImage) {
      this.restoreAttribute("srcset")
      this.restoreAttribute("src")
      processedImageURLs.add(this.element.src)
    }
  }

  stashAttribute(name) {
    const value = this.element.getAttribute(name)
    if (value != null) {
      this.element.removeAttribute(name)
      this.data.set(name, value)
    }
  }

  restoreAttribute(name) {
    const value = this.data.get(name)
    if (value != null) {
      this.element.setAttribute(name, value)
      this.data.delete(name)
    }
  }
}
