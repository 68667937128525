export class ViewportEntranceObserver {
  constructor(rootMargin = "40px") {
    this.delegatesByElement = new WeakMap
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), { rootMargin })
  }

  observe(element, delegate) {
    if (!this.delegatesByElement.has(element)) {
      this.delegatesByElement.set(element, delegate)
      this.intersectionObserver.observe(element)
    }
  }

  stopObserving(element) {
    if (this.delegatesByElement.has(element)) {
      this.delegatesByElement.delete(element)
      this.intersectionObserver.unobserve(element)
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.elementWillEnterViewport(entry.target)
      }
    })
  }

  elementWillEnterViewport(element) {
    const delegate = this.delegatesByElement.get(element)
    if (delegate) {
      this.stopObserving(element)
      if (delegate.elementWillEnterViewport) {
        delegate.elementWillEnterViewport(element)
      }
    }
  }
}
