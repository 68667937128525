import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.formTargets.forEach(el => {
      if (el.dataset.type === "hound") {
        el.addEventListener("ajax:success", this.handleHoundCreate);
      } else if (el.dataset.type === "breed-centre") {
        el.addEventListener("ajax:success", this.handleBreedCentreCreate);
      } else {
        return;
      }
    });
  }

  disconnect() {
    this.formTargets.forEach(el => {
      if (el.dataset.type === "hound") {
        el.removeEventListener("ajax:success", this.handleHoundCreate);
      } else if (el.dataset.type === "breed-centre") {
        el.removeEventListener("ajax:success", this.handleBreedCentreCreate);
      } else {
        return;
      }
    });
  }

  handleBreedCentreCreate = event => {
    const raw = this.element.querySelector("[data-target~=breed-centre-input]");
    const select = raw ? raw.slim : null;
    const xhr = event.detail[0];

    if (select && xhr) {
      const { data: existing } = select.data;

      select.setData([...existing, { text: xhr.name, value: xhr.id }]);
      select.set(xhr.id);
    }

    this.toggleForm(event);
  };

  handleHoundCreate = event => {
    const xhr = event.detail[0];

    if (xhr.length === 0) return;

    const { sex } = xhr;
    const raw = document.querySelector(
      `[data-target~=hound-parent-${sex}-input]`
    );
    const select = raw ? raw.slim : null;

    if (select) {
      const { data: existing } = select.data;

      select.setData([
        ...existing,
        { text: xhr.formatted_name, value: xhr.id }
      ]);
      select.set(xhr.id);
    }

    const modalTarget = document.getElementById("app-modal");
    if (modalTarget) modalTarget.close();
  };

  toggleForm(event) {
    event.preventDefault();
    this.formTargets.forEach(target => {
      target.classList.toggle("hidden");

      if (target.offsetParent !== null) {
        const focusable = target.querySelector("[autofocus]");
        focusable != null && focusable.focus();
      }
    });
  }
}
