// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Rails functionality
window.Rails = require("@rails/ujs");
require("turbolinks").start();
require("trix");
require("@rails/actiontext");

// Tailwind CSS
require("stylesheets/application.scss");

// Stimulus controllers
import "controllers";

// HoundApp functionality
import "src/actiontext";
import "src/confirm";
import "src/forms";
import "src/table";
import "src/tooltips";
import "src/modal_component";

require("src/mapkick");

// Start Rails UJS
Rails.start();
