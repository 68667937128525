import { Controller } from "stimulus";

// Uppy S3 file upload
import { singleFileUpload } from "src/direct_upload";

export default class extends Controller {
  static targets = ["fileInput"];

  initialize() {
    this.fileInputTargets.forEach(fileInput => {
      singleFileUpload(fileInput);
    });
  }
}
