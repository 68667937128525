import { Application } from "stimulus";
import RevealController from 'stimulus-reveal'
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

import { Dropdown, Tabs } from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);
application.register("reveal", RevealController)
application.register("tabs", Tabs);
