import { Controller } from "stimulus";
import { createPopper } from "@popperjs/core/lib/popper-lite.js";
import { rFetch } from "src/utils";

export default class extends Controller {
  static targets = ["content"];
  static timeout;

  initialize() {
    if (this.strategy === "center") {
      this.contentTarget.setAttribute(
        "style",
        "transform:translate(-50%, -50%); top:50%; left:50%; position:fixed;"
      );
    } else if (this.strategy === "custom") {
      this.contentTarget.setAttribute(
        "style",
        `transform:translate(${this.data.get("translateX")}, ${this.data.get(
          "translateY"
        )});`
      );
    } else {
      createPopper(this.element, this.contentTarget, {
        strategy: "fixed",
      });
    }

    this.contentTarget.innerHTML = ""
  }

  connect() {
    if (this.loadable) this.loaded = false
    this.visible = false
  }

  disconnect() {
    if (this.loadable) this.loaded = false
    this.visible = false
  }

  mouseOver() {
    if (this.visible) return;

    this.timeout = setTimeout(() => {
      this.show();
      if (this.loadable) this.load();
      document.addEventListener("mousemove", this.mouseOut.bind(this));
    }, this.delay);
  }

  mouseOut(event) {
    if (this.element.contains(event.target)) return;

    clearTimeout(this.timeout);
    this.hide();
    this.element.removeEventListener("mousemove", this.mouseOut);
  }

  load() {
    if (!this.loaded) {
      this.contentTarget.classList.add("loading");

      rFetch(this.url, {
        as: "xhr",
        contentType: "application/javascript",
      })
        .then((content) => {
          this.contentTarget.innerHTML = content;
          this.data.set("loaded", true);
        })
        .finally(() => this.contentTarget.classList.remove("loading"));
    }
  }

  show() {
    this.contentTarget.classList.remove("hidden");
    this.visible = true;
  }

  hide() {
    this.contentTarget.classList.add("hidden");
    this.visible = false;
  }

  get delay() {
    return (this.data.get("delay") || 0) * 1000;
  }

  get loadable() {
    return this.data.has("url");
  }

  get loaded() {
    return this.data.has("loaded") && this.data.get("loaded") == "true";
  }

  set loaded(value) {
    return this.data.set("loaded", value);
  }

  get strategy() {
    return this.data.has("strategy") ? this.data.get("strategy") : "popper";
  }

  get url() {
    return this.data.get("url");
  }

  get visible() {
    return this.data.get("visibility") === "true";
  }

  set visible(value) {
    return this.data.set("visibility", value);
  }
}
