import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (!this.loaded) {
      this.load();
    }
  }

  load() {
    fetch(this.url)
      .then(response => {
        return response.text();
      })
      .then(content => {
        this.element.innerHTML = content;
        this.data.set("loaded", true);
      });
  }

  get url() {
    return this.data.get("url");
  }

  get loaded() {
    return this.data.get("loaded");
  }
}
