import { Controller } from "stimulus";

export default class extends Controller {
  static timeout;

  connect() {
    if (!this.isLocal) {
      this.element.addEventListener("change", this.handleSubmit.bind(this));
      // this.element.addEventListener("input", this.handleInput.bind(this));
      this.element.addEventListener("keydown", this.handleKeydown.bind(this));
    }

    this.element.addEventListener(
      "ajax:beforeSend",
      this.handleRender.bind(this)
    );
  }

  disconnect() {
    if (!this.isLocal) {
      this.element.removeEventListener("change", this.handleSubmit);
      // this.element.removeEventListener("input", this.handleInput);
      this.element.removeEventListener("keydown", this.handleKeydown);
    }

    this.element.removeEventListener("ajax:beforeSend", this.handleRender);

    this.timeout = undefined;
  }

  // handleInput(event) {
  // clearTimeout(this.timeout);

  // this.timeout = setTimeout(() => {
  //   this.handleSubmit(event);
  //   this.setFocus(event.target);
  // }, 999);
  // }

  handleKeydown(event) {
    const { keyCode } = event;
    if (keyCode === 13) {
      this.handleSubmit(event);
    }
  }

  handleSubmit(event) {
    clearTimeout(this.timeout);
    const performSearch = this.submittableField(event);

    if (performSearch) {
      event.preventDefault();

      if (event.type === "change" && event.target.type === "number") {
        this.timeout = setTimeout(() => {
          Rails.fire(this.element, "submit");
          this.setFocus(event.target);
        }, 999);
      } else {
        Rails.fire(this.element, "submit");
      }
    }
  }

  handleRender(event) {
    if (event.target === this.element) {
      event.preventDefault();

      const { detail } = event;
      const xhr = detail[0];
      const options = detail[1];

      Turbolinks.visit(options.url, { action: "replace" });
    }
  }

  submittableField(event) {
    const { target: field, type } = event;

    if (!this.regexp.test(field.getAttribute("name"))) return false;
    if (!this.formFields.includes(field)) return false;

    switch (type) {
      case "input":
      case "keydown":
        return ["number", "text"].includes(field.type);
        break;
      case "change":
        return ["number", "radio", "hidden", "select-one", "select-multiple"].includes(field.type);
        break;
      default:
        break;
    }
  }

  setFocus(field) {
    return setTimeout(() => {
      const input = document.getElementById(field.id);

      if (input && this.regexp.test(input.getAttribute("name"))) {
        input.focus();

        const value = input.value;
        input.value = "";
        input.value = value;
      }
    }, 2000);
  }

  get isLocal() {
    return this.data.get("local") === "true"
  }

  get formFields() {
    return Array.from(this.element.elements);
  }

  get regexp() {
    return new RegExp("q[[a-z_]+]");
  }
}
