import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["shelf"];

  initialize() {
    this.shelfTargets.forEach(shelf => {
      const trigger = document.querySelector(
        `[data-trigger-id~=${this.trigger}]`
      );

      switch (trigger.type) {
        case "radio":
          const checked = document.querySelector(
            `[data-trigger-id~=${this.trigger}]:checked`
          );

          return shelf.classList.toggle(
            "visually-hidden",
            checked.checked && !(checked.value === shelf.dataset.triggerOn)
          );
          break;
        case "checkbox":
          shelf.classList.toggle(
            "visually-hidden",
            !(trigger.checked === this.toBoolean(shelf.dataset.triggerOn))
          );
          break;
        case "select-one":
          shelf.classList.toggle(
            "visually-hidden",
            !(trigger.value === shelf.dataset.triggerOn)
          );
          break;
        default:
          break;
      }
    });
  }

  select(event) {
    event.preventDefault();

    this.shelfTargets.forEach(shelf => {
      switch (event.target.type) {
        case "radio":
          shelf.classList.toggle(
            "visually-hidden",
            !(
              event.target.checked &&
              event.target.value === shelf.dataset.triggerOn
            )
          );
          break;
        case "checkbox":
          shelf.classList.toggle(
            "visually-hidden",
            !(event.target.checked === this.toBoolean(shelf.dataset.triggerOn))
          );
          break;
        case "select-one":
          shelf.classList.toggle(
            "visually-hidden",
            !(event.target.value === shelf.dataset.triggerOn)
          );
          break;
        default:
          break;
      }
    });
  }

  toBoolean(value) {
    return value === "true";
  }

  get trigger() {
    return this.data.get("trigger");
  }
}
