import { Controller } from "stimulus";
import { refreshWithScrollLock } from "src/utils";

export default class extends Controller {
  connect() {
    this.element.addEventListener("ajax:success", this.onSuccess);
  }

  disconnect() {
    this.element.removeEventListener("ajax:success", this.onSuccess);
  }

  onSuccess = event => refreshWithScrollLock();
}
