import { Controller } from "stimulus";
import raf from "raf-schd";

const performScroll = (context, scrollable) => {
  const thead = context.theadTarget;
  const tbody = context.tbodyTarget;

  if (scrollable === tbody) {
    thead.scrollLeft = tbody.scrollLeft;
  } else if (scrollable === thead) {
    tbody.scrollLeft = thead.scrollLeft;
  }
};

const scheduleScrollSync = raf(performScroll);

export default class extends Controller {
  static targets = ["table", "thead", "tbody"];

  connect() {
    setTimeout(() => {
      this.assignDimensions();
      this.setTableHeight();
    }, 300);

    this.theadTarget.addEventListener(
      "scroll",
      this.syncScroll.bind(this),
      true
    );

    this.tbodyTarget.addEventListener(
      "scroll",
      this.syncScroll.bind(this),
      true
    );
  }

  disconnect() {
    this.theadTarget.removeEventListener("scroll", this.syncScroll, true);
    this.tbodyTarget.removeEventListener("scroll", this.syncScroll, true);
  }

  assignDimensions() {
    const lastHeadRow = this.theadTarget.querySelector("tr:last-of-type");
    const firstCellRow = this.tbodyTarget.querySelector("tr:first-of-type");

    const lastHeadRowCells = this.theadTarget.querySelectorAll(
      "tr:last-of-type > th"
    );
    const firstCellRowCells = this.tbodyTarget.querySelectorAll(
      "tr:first-of-type > td"
    );

    const tableWidth =
      Math.min(this.tableTarget.getBoundingClientRect().width, this.tbodyTarget.scrollWidth) || 0;

    const rowWidth = Math.max(
      lastHeadRow.getBoundingClientRect().width || 0,
      firstCellRow.getBoundingClientRect().width || 0
    );

    const scrollbarWidth =
      (this.tableTarget.getBoundingClientRect().width || 0) -
      (this.tbodyTarget.scrollWidth || 0);

    const firstCell = firstCellRowCells[0];
    const isEmpty = firstCell.hasAttribute("colspan") &&
      firstCell.getAttribute("colspan") === "99";

    // 2px KVULI BORDER-WIDTH left <=> right
    const diff = Math.floor(tableWidth - rowWidth - 2);
    const length = isEmpty ? lastHeadRowCells.length : firstCellRowCells.length;
    let toAdd = diff > 0 ? Math.floor(diff / length) : 0;

    let total = 0;

    for (let idx = 0; idx < length; idx++) {
      const elem1 = lastHeadRowCells[idx];
      const elem2 = isEmpty ? lastHeadRowCells[idx] : firstCellRowCells[idx];

      const rect1 = elem1.getBoundingClientRect();
      const rect2 = elem2.getBoundingClientRect();

      const width1 = rect1.width;
      const width2 = rect2.width;

      let width = 0;

      if (idx === 0 && isEmpty) {
        width = Math.ceil(Math.min(width1, width2));
      } else {
        const maxTemp = Math.ceil(Math.max(width1, width2));
        const minTemp = Math.ceil(Math.min(maxTemp, this.maxWidth));
        width = idx === 0 ? maxTemp : minTemp;
      }

      total += (width + toAdd);

      // JENOM POKUD MÁME TABULKU, KTERÁ BY SE MĚLA VEJÍT NA STRÁNKU
      if (diff > 0 && total > tableWidth) {
        toAdd = toAdd + (tableWidth - total);
      }

      elem1.style.width = `${width + toAdd}px`;
      elem1.style.minWidth = `${width + toAdd}px`;
      elem1.style.maxWidth = `${width + toAdd}px`;

      elem2.style.width = `${width + toAdd}px`;
      elem2.style.minWidth = `${width + toAdd}px`;
      elem2.style.maxWidth = `${width + toAdd}px`;
  }

    if (isEmpty) {
      firstCell.setAttribute("colspan", length);
      firstCell.style.width = `100vw`;
    }
  }

  syncScroll(event) {
    scheduleScrollSync(this, event.currentTarget);
  }

  setTableHeight() {
    const navbar = document.getElementById("app-navbar");
    const content = document.getElementById("app-content");
    const footer = document.getElementById("app-footer");

    if (navbar == null || content == null || footer == null) return;

    const tbody = this.tbodyTarget;

    const navbarRect = navbar.getBoundingClientRect();
    const contentRect = content.getBoundingClientRect();
    const footerRect = footer.getBoundingClientRect();
    const tbodyRect = tbody.getBoundingClientRect();

    const spacer = Math.ceil((footerRect.top || 0) - (contentRect.bottom || 0));
    const tbodyHeight = tbodyRect.height + spacer;

    tbody.style.height = `${tbodyHeight}px`;
  }

  get maxWidth() {
    return 360;
  }
}
