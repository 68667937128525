import { Controller } from "stimulus";
import { refreshWithScrollLock } from "src/utils";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.formTarget.addEventListener("ajax:success", this.handleSubmit);
  }

  disconnect() {
    this.formTarget.removeEventListener("ajax:success", this.handleSubmit);
  }

  handleSubmit = event => {
    const modalTarget = document.getElementById("app-modal");
    if (modalTarget) modalTarget.close();
    return refreshWithScrollLock()
  };
}
