import { Sortable, Plugins } from "@shopify/draggable";
import { rFetch, refreshWithScrollLock } from "./utils";

const Classes = {
  draggable: "StackedListItem--isDraggable",
  capacity: "draggable-container-parent--capacity"
};

export const initQuintessentialDnD = ({
  itemSelector,
  listSelector,
  param,
  url
} = props) => {
  const containers = document.querySelectorAll(
    `[data-target~=${listSelector}]`
  );

  if (containers.length === 0) {
    return false;
  }

  const sortable = new Sortable(containers, {
    draggable: `[data-target~=${itemSelector}]`,
    mirror: {
      constrainDimensions: true
    },
    plugins: [Plugins.ResizeMirror]
  });

  // --- Draggable events --- //
  sortable.on("sortable:sort", evt => {
    const { overContainer, sourceContainer } = evt.dragEvent;

    if (!isListDroppable(overContainer, sourceContainer)) {
      evt.cancel();
    }
  });

  sortable.on("sortable:stop", evt => {
    const { detail, newContainer, oldContainer } = evt;
    const canceled = !isListDroppable(newContainer, oldContainer);

    if (canceled) return false;

    const {
      newIndex,
      dragEvent: { originalSource }
    } = evt;
    const handle = originalSource.dataset.draggableId;

    if (newContainer === oldContainer) {
      // Sort event
      let formData = new FormData();
      formData.append(`${param}[position]`, newIndex);

      rFetch(`${url}/${handle}`, {
        method: "PATCH",
        body: formData
      }).then(_res => {
        return refreshWithScrollLock();
      });
    } else if (oldContainer.dataset.columnIdx === "origin") {
      // Create event
      let formData = new FormData();
      formData.append(`${param}[code]`, handle);
      formData.append(`${param}[position]`, newIndex);

      rFetch(`${url}`, {
        method: "POST",
        body: formData
      }).then(_res => {
        return refreshWithScrollLock();
      });
    } else if (oldContainer.dataset.columnIdx === "copy") {
      // Delete event
      rFetch(`${url}/${handle}`, {
        method: "DELETE"
      }).then(_res => {
        return refreshWithScrollLock();
      });
    }
  });

  return sortable;
};

const isListDroppable = (newContainer, oldContainer) => {
  return (
    newContainer.dataset.itemsSortable === "true" ||
    oldContainer.dataset.itemsSortable === "true"
  );
};
