import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      "ajax:beforeSend",
      this.removeExistingErrorMessages.bind(this)
    );
    this.element.addEventListener("ajax:success", this.onSuccess);
    this.element.addEventListener("ajax:error", this.onError);
  }

  disconnect() {
    this.element.removeEventListener(
      "ajax:beforeSend",
      this.removeExistingErrorMessages.bind(this)
    );
    this.element.removeEventListener("ajax:success", this.onSuccess);
    this.element.removeEventListener("ajax:error", this.onError);
  }

  onSuccess = event => {};

  onError = event => {
    this.removeExistingErrorMessages();

    if (event.detail[2].status !== 422) return;
    const errors = event.detail[0];
    this.showErrorForInvalidFields(errors);
  };

  shouldValidateField(field) {
    return (
      !field.disabled &&
      field.type !== undefined &&
      !["file", "reset", "submit", "button"].includes(field.type)
    );
  }

  removeExistingErrorMessages() {
    this.formFields.forEach(field => {
      field.classList.remove("error");

      const fieldContainer = field.closest(".form-group");
      if (!fieldContainer) return;
      const existingErrorMessageElement = fieldContainer.querySelector(
        "p.error"
      );
      if (existingErrorMessageElement)
        existingErrorMessageElement.parentNode.removeChild(
          existingErrorMessageElement
        );
    });
  }

  showErrorForInvalidFields(errors) {
    for (let [key, values] of Object.entries(errors)) {
      const field = this.element.querySelector(`[id$="${key}"]`);

      if (field && this.shouldValidateField(field)) {
        this.showErrorForInvalidField(field, values);
      }
    }
  }

  showErrorForInvalidField(field, values) {
    const fieldContainer = field.closest(".form-group");
    field.classList.add("error");

    values.forEach(message => {
      fieldContainer.insertAdjacentHTML(
        "beforeend",
        this.buildFieldErrorHtml(message)
      );
    });
  }

  buildFieldErrorHtml(message) {
    return `<p class="form-hint error">${message}</p>`;
  }

  get formFields() {
    return Array.from(this.element.elements);
  }

  get firstInvalidField() {
    return this.formFields.find(field => !field.checkValidity());
  }
}
