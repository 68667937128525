import { Controller } from "stimulus";
import { removeElement } from "../src/utils";

export default class extends Controller {
  static targets = ["slot", "template", "toggleable", "value"];
  static eventHandlers = [];

  connect() {
    this.eventHandlers = [];
  }

  disconnect() {
    this.eventHandlers = [];
  }

  installForm(event) {
    event.preventDefault();

    const content = this.templateTarget.innerHTML;
    this.slot.insertAdjacentHTML("afterbegin", content);
    this.visible = true;
    this.setCurrentValue();
    this.setPosition();
    this.addEventListeners();
    this.toggleForm();
  }

  uninstallForm(event) {
    event.preventDefault();

    removeElement(this.form);
    this.removeEventListeners();
    this.visible = false;
    this.toggleForm();
  }

  toggleForm() {
    this.toggleableTarget.classList.toggle("hidden", this.visible);
  }

  addEventListeners = () => {
    const form = this.form;
    const close = document.getElementById("editable-input-close");

    document.addEventListener("click", this.handleClick.bind(this), true);
    if (close)
      close.addEventListener("click", this.uninstallForm.bind(this), true);
    document.addEventListener("keyup", this.handleKeyup.bind(this));
    if (form) form.addEventListener("ajax:success", this.handleSubmit);
  };

  removeEventListeners = () => {
    if (!this.visible) return;

    const form = this.form;
    const close = document.getElementById("editable-input-close");

    document.removeEventListener("click", this.handleClick, true);
    if (close) close.removeEventListener("click", this.uninstallForm, true);
    document.removeEventListener("keyup", this.handleKeyup);
    if (form) form.removeEventListener("ajax:success", this.handleSubmit);
  };

  handleClick(event) {
    const form = this.form;
    const target = event.target;

    if (!form) return;
    if (form == target || (form !== target && form.contains(target))) return;

    this.uninstallForm(event);
  }

  handleKeyup(event) {
    event.preventDefault();
    event.stopPropagation();

    const { keyCode } = event;

    if (keyCode === 27) {
      this.uninstallForm(event);
    } else if (keyCode === 13) {
      const form = this.form;

      if (!form) return;

      Rails.fire(form, "submit");
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const xhr = event.detail[0];

    if (xhr) {
      const value = xhr[this.field];

      this.valueTarget.textContent = value;
      this.value = value;
    }

    this.uninstallForm(event);
  };

  setCurrentValue() {
    const form = this.form;
    if (form) {
      const field = form.querySelector(`[id$="${this.field}"]`);
      field.value = this.value;
    }
  }

  setPosition() {
    const rect = this.element.getBoundingClientRect();
    const form = this.form;

    if (form) {
      form.style.top = `${Math.ceil(rect.top) - 16 + window.scrollY}px`;
      form.style.left = `${Math.ceil(rect.left) - 16 + window.scrollX}px`;
    }
  }

  resetPosition() {
    const form = this.form;

    if (form) {
      form.style.top = 0;
      form.style.left = 0;
    }
  }

  get field() {
    return this.data.get("field");
  }

  get firstInvalidField() {
    return this.formFields.find(field => !field.checkValidity());
  }

  get form() {
    return this.visible ? this.slot.children[0] : null;
  }

  get slot() {
    if (this.element.getElementsByTagName("SLOT").length === 0) {
      return document.getElementById("editable-input-slot");
    } else {
      return this.slotTarget;
    }
  }

  get value() {
    return this.data.get("value");
  }

  set value(value) {
    return this.data.set("value", value);
  }

  get visible() {
    return this.data.get("visible") === "true";
  }

  set visible(value) {
    return this.data.set("visible", value);
  }
}
