import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("ajax:success", this.onSuccess);
  }

  disconnect() {
    this.element.removeEventListener("ajax:success", this.onSuccess);
  }

  onSuccess = event => {
    const raw = document.querySelector("[data-target~=breed-centre-input]");
    const select = raw ? raw.slim : null;
    const xhr = event.detail[0];

    if (select && xhr) {
      const { data: existing } = select.data;

      select.setData([...existing, { text: xhr.name, value: xhr.id }]);
      select.set(xhr.id);
    }

    const modalTarget = document.getElementById("app-modal");
    if (modalTarget) modalTarget.close();
  };
}
