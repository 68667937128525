import { Controller } from "stimulus";

export default class extends Controller {
  clear(evt) {
    const target = evt.target;
    const value = target.value;

    if (value && parseInt(value) === 0) {
      target.value = "";
    }
  }
}
