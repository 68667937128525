import { Controller } from "stimulus";
import { findElements, toArray } from "src/utils";

export default class extends Controller {
  static targets = ["embeddable", "modal", "markerToggle"];

  connect() {
    if (window.cachedMaps == null) window.cachedMaps = {};
    if (window.coordsFromSuggest == null) window.coordsFromSuggest = {};

    this.service = this.createService();

    if (this.mapConfig.inputable) this.initTogglers();

    if (!this.initVisible) return;

    if (this.service) {
      this.service.initialize(() => this.loadScript());
    }
  }

  disconnect() {
    window.cachedMaps = null;
    window.coordsFromSuggest = null;
  }

  embed(event) {
    const element = this.element.querySelector(`#${event.target.dataset.mapTarget}`);
    if (this.scriptLoaded) this.service.create(this.element, element);
  }

  displayMarker(event) {
    const element = this.element.querySelector(`#${event.target.dataset.mapTarget}`);
    if (this.scriptLoaded) {
      this.service.displayMarker(this.element, element);
      this.toggleMarkers(this.element, event.target.dataset.toggleType);
    }
  }

  removeMarker(event) {
    const element = this.element.querySelector(`#${event.target.dataset.mapTarget}`);
    if (this.scriptLoaded) {
      this.service.removeMarker(this.element, element);
      this.toggleMarkers(this.element, event.target.dataset.toggleType);
    }
  }

  // PRIVATE FUNCTIONS

  createService() {
    return new MapService(this);
  }

  loadScript() {
    Loader.async = true;
    Loader.load(
      null,
      { suggest: this.mapConfig.autosuggest },
      this.init.bind(this)
    );
  }

  init() {
    if (this.scriptLoaded) {
      this.service.create(this.element, this.embeddableTarget);
    }
  }

  initTogglers() {
    const [latitude, longitude] = this.service.getCoordsFromInput(this.element);

    const hasCoords = longitude !== null && latitude !== null;
    const toggleClass = hasCoords ? "enable" : "disable";

    this.toggleMarkers(this.element, toggleClass);
  }

  toggleMarkers(container, toggleClass) {
    const elements = findElements(container, "[data-role~=map-marker-toggle]");

    toArray(elements).forEach(elem => {
      elem.classList.toggle(
        "visually-hidden",
        elem.dataset.toggleType === toggleClass
      );
    });
  }

  get initVisible() {
    return this.toBoolean(this.data.get("initVisible"));
  }

  get mapConfig() {
    return JSON.parse(this.data.get("config"));
  }

  get geometryConfig() {
    return JSON.parse(this.data.get("geometries"));
  }

  get mapId() {
    return this.data.get("id");
  }

  get scriptLoaded() {
    return !!window.Loader;
  }

  toBoolean(value) {
    return value === "true";
  }
}

class MapService {
  constructor(ctx) {
    this.container = ctx.element;
    this.config = ctx.mapConfig;
    this.geometries = ctx.geometryConfig;
  }

  initialize(callback) {
    if (this.isLoaded) {
      callback();
    } else {
      const script = document.createElement("script");
      script.setAttribute("async", true);
      script.setAttribute("id", "seznam");
      script.setAttribute("src", "//api.mapy.cz/loader.js");
      script.setAttribute("type", "text/javascript");

      const onScriptLoad = () => callback();

      const onScriptError = () => {
        script.remove();
      };

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
      };
    }
  }

  create = (container, elem) => {
    const uuid = `${container.id}-${elem.id}`
    let m = window.cachedMaps[uuid];
    let coordsFromSuggest = window.coordsFromSuggest;

    const [latitude, longitude] = this.getCoordsFromInput(container);
    const [rawCoords, useFallbackCoords] = this.getCoords(container);
    const coords = SMap.Coords.fromWGS84(rawCoords.lon, rawCoords.lat);

    const {
      autosuggest,
      inputable,
      marker_layer: { enable: enableMarker, draggable, ...markerOptions }
    } = this.config;

    let hasCoords = false;
    if (!inputable || (longitude && latitude)) {
      hasCoords = true;
    }

    if (m == null) {
      m = new SMap(elem, coords, useFallbackCoords ? 9 : 17);
      window.cachedMaps[uuid] = m;

      m.addDefaultLayer(SMap.DEF_BASE).enable();
      m.addDefaultLayer(SMap.DEF_OPHOTO).enable();
      m.addDefaultLayer(SMap.DEF_HYBRID_SPARSE).enable();
      m.addDefaultControls();

      let suggest = null;
      if (autosuggest) {
        const suggestInput = container.querySelector(`#${elem.id}-search-input`);
        suggest = new SMap.Suggest(suggestInput);
        suggest.urlParams({
          bounds: "48.5370786,12.0921668|51.0746358,18.8927040", // Omezení pro ČR
          enableCategories: 1,
          lang: "cs,en"
        });
      }

      const signals = m.getSignals();
      const controls = m.getControls();
      for (let i = 0; i < controls.length; i++) {
        if (
          controls[i] instanceof SMap.Control.Compass ||
          controls[i] instanceof SMap.Control.Zoom
        ) {
          m.removeControl(controls[i]);
          i--;
        }
      }

      if (suggest !== null) {
        suggest
          .addListener("suggest", func => {
            const data = func.data;
            const suggested = SMap.Coords.fromWGS84(
              data.longitude,
              data.latitude
            );

            window.coordsFromSuggest = Object.assign(coordsFromSuggest, {
              lon: data.longitude,
              lat: data.latitude
            });
            m.setCenterZoom(suggested, 17);
          })
          .addListener("close", () => {});
      }

      window.cachedMaps[uuid] = m;
      if (enableMarker && hasCoords) this.displayMarker(container, elem);
    } else {
      window.cachedMaps[uuid] = m;

      if (hasCoords) {
        this.displayMarker(container, elem);
      } else {
        this.removeMarker(container, elem);
      }
    }

    window.cachedMaps[uuid] = m;

    if (Object.keys(this.geometries).length > 0) {
      const { marker_layer: { icon } } = this.config

      this.geometries.forEach(geometry => {
        const { type, id, data, options } = geometry

        new Mapkick[type](id, data, m, Object.assign(options,{ icon }))
      })
    }

    m.syncPort();
  };

  displayMarker = (container, elem) => {
    const uuid = `${container.id}-${elem.id}`
    const map = window.cachedMaps[uuid];
    if (map == null) return;

    const {
      inputable,
      marker_layer: { id }
    } = this.config;
    const layer = map.getLayer(id);
    const [rawCoords, useFallbackCoords] = this.getCoords(container);
    const coords = SMap.Coords.fromWGS84(rawCoords.lon, rawCoords.lat);

    if (layer == null) {
      this.initMarker(container, elem, coords);
    } else {
      this.resetMarker(id, map, layer, coords);
    }

    if (inputable) this.setInputCoords(container, coords.y, coords.x);
    map.setCenterZoom(coords, useFallbackCoords ? 9 : 17);
  };

  initMarker = (container, elem, coords) => {
    const uuid = `${container.id}-${elem.id}`
    const m = window.cachedMaps[uuid];

    const {
      inputable,
      marker_layer: { enable: enableMarker, draggable, ...markerOptions }
    } = this.config;

    const { id, icon } = markerOptions;
    const markerLayer = new SMap.Layer.Marker(id);
    const marker = new SMap.Marker(coords, `${id}_marker`, {
      url: icon
    });

    if (draggable) marker.decorate(SMap.Marker.Feature.Draggable);

    m.addLayer(markerLayer).enable();
    markerLayer.addMarker(marker);

    const signals = m.getSignals();

    if (inputable) {
      signals.addListener(window, "map-click", evt => {
        const gps = SMap.Coords.fromEvent(evt.data.event, m);
        marker.setCoords(gps);

        new SMap.Geocoder.Reverse(gps, this.geocode);
      });
    }

    if (draggable) {
      signals.addListener(window, "marker-drag-stop", this.dragStop);
      signals.addListener(window, "marker-drag-start", this.dragStart);
    }
  };

  removeMarker = (container, elem) => {
    const uuid = `${container.id}-${elem.id}`
    const { id } = this.config.marker_layer;
    const map = window.cachedMaps[uuid];
    const layer = map.getLayer(id);

    this.removeAttachedListeners(map);

    if (layer !== null) {
      layer.removeAll();
      map.removeLayer(layer);
    }

    this.resetInputCoords(container);
  };

  resetMarker = (id, map, layer, coords) => {
    const marker = layer._markers[`${id}_marker`].marker;
    marker.setCoords(coords);
  };

  getCoords = (container) => {
    const suggested = window.coordsFromSuggest;
    const [latitude, longitude] = this.getCoordsFromInput(container);

    const useFallbackCoords =
      (longitude || (suggested && suggested["lon"]) || this.config.lon) ==
        null &&
      (latitude || (suggested && suggested["lat"]) || this.config.lat) == null;

    const coords = Object.assign(
      {},
      {
        lon:
          longitude ||
          (suggested && suggested["lon"]) ||
          this.config.lon ||
          this.config.fallback_lon,
        lat:
          latitude ||
          (suggested && suggested["lat"]) ||
          this.config.lat ||
          this.config.fallback_lat
      }
    );

    return [coords, useFallbackCoords];
  };

  dragStart = evt => {
    const container = evt.target.getContainer();
    container[SMap.LAYER_MARKER].style.cursor = "move";
  };

  dragStop = evt => {
    const container = evt.target.getContainer();
    const coords = evt.target.getCoords();
    container[SMap.LAYER_MARKER].style.cursor = "";

    return new SMap.Geocoder.Reverse(coords, this.geocode);
  };

  geocode = geocoder => {
    const { lat_input_id: latitudeId, lon_input_id: longitudeId } = this.config;
    const results = geocoder.getResults();
    const data = Object.assign({
      latitude: results.coords.y,
      longitude: results.coords.x
    });

    if (!this.setInputCoords(this.container, data.latitude, data.longitude)) return data;
  };

  redraw = (container, map) => {
    const [latitude, longitude] = this.getCoordsFromInput(container);
    const coords = SMap.Coords.fromWGS84(
      longitude || this.config.lon,
      latitude || this.config.lat
    );

    map.redraw();
  };

  removeAttachedListeners = map => {
    if (map == null) return;

    const signals = map.getSignals();
    const { draggable } = this.config.marker_layer;
    let listeners = ["map-click"];

    if (draggable) {
      listeners.push("marker-drag-start");
      listeners.push("marker-drag-stop");
    }

    listeners.forEach(key => {
      const listener = signals._myHandleFolder[key];

      Object.keys(listener).forEach(identifier => {
        signals.removeListener(identifier);
      });
    });
  };

  getCoordsFromInput = (container) => {
    const { lat_input_id: latitudeId, lon_input_id: longitudeId } = this.config;
    if (latitudeId && longitudeId) {
      const latInput = container.querySelector(`#${latitudeId}`);
      const lonInput = container.querySelector(`#${longitudeId}`);

      if (!(latInput && latInput.value) && !(lonInput && lonInput.value)) {
        return [null, null];
      }

      return [latInput.value, lonInput.value];
    } else {
      return [null, null];
    }
  };

  setInputCoords = (container, lat, lon) => {
    const { lat_input_id: latitudeId, lon_input_id: longitudeId } = this.config;
    if (!(latitudeId && longitudeId)) return false;

    const latInput = container.querySelector(`#${latitudeId}`);
    const lonInput = container.querySelector(`#${longitudeId}`);

    if (!(latInput && lonInput)) return false;

    latInput.value = lat;
    lonInput.value = lon;
  };

  resetInputCoords(container) {
    this.setInputCoords(container, null, null);
  }

  get isLoaded() {
    return !!window.Loader;
  }
}
