import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slide", "button"];

  initialize() {
    this.showCurrentSlide();
    this.toggleButtons();
  }

  next(event) {
    if (this.index !== this.count) this.index++;
    this.toggleButtons();
  }

  previous(event) {
    if (this.index !== 0) this.index--;
    this.toggleButtons();
  }

  showCurrentSlide() {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle("slide--current", this.index == i);
    });
  }

  toggleButtons() {
    this.buttonTargets.forEach(el => {
      const type = el.dataset.type;

      if (type === "previous") {
        el.classList.toggle("visually-hidden", this.index === 0);
      } else {
        el.classList.toggle("visually-hidden", this.index === this.count);
      }
    });
  }

  get count() {
    return this.slideTargets.length > 0 ? this.slideTargets.length - 1 : 0;
  }

  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    this.data.set("index", value);
    this.showCurrentSlide();
  }
}
