import { Controller } from "stimulus";
import SlimSelect, { debounce } from "slim-select";
import qs from "qs";
import removeAccents from "remove-accents";
import { rFetch, safeParse } from "src/utils";

const fetchData = (url, callbackFn, search = {}) => {
  rFetch(url, {
    as: "json",
    contentType: "application/json",
    query: {
      q: search,
    },
  })
  .then(callbackFn)
};

export default class extends Controller {
  static select;
  static timeout;

  connect() {
    let opts = {
      allowDeselect: true,
      allowDeselectOption: true,
      beforeOpen: this.handleOpen.bind(this),
      closeOnSelect: !this.element.multiple,
      deselectLabel: '<span class="text-xs">✖</span>',
      hideSelectedOption: true,
      onChange: this.maybeAddSelectOptions.bind(this),
      placeholder:
        this.element.dataset.placeholder || "Vyberte z možností\u2026",
      searchPlaceholder: "Hledat\u2026",
      searchText: "Nic nenalezeno\u2026",
      select: this.element
    }

    if (this.fetchOnSearch) {
      opts = {
        ...opts,
        ajax: this.handleSearchDebounced.bind(this),
        searchingText: "Probíhá hledání\u2026",
        searchFilter: ({ text }, search) => true
      }
    } else {
      opts = {
        ...opts,
        searchFilter: ({ text }, search) =>
          removeAccents(text)
            .toLowerCase()
            .indexOf(removeAccents(search).toLowerCase()) !== -1
      }
    }

    this.select = new SlimSelect(opts);

    if (this.fetchOnConnect && !this.fetchOnSearch) {
      fetchData(this.url, this.populateOptions)
    }
  }

  disconnect() {
    this.select.destroy();
    this.select = null;
  }

  handleOpen = (info = null) => {
    this.calculateDropdownPosition();
    this.maybeAddSelectOptions(info);
  };

  handleSearchDebounced = (query, callbackFn) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.handleSearch(query, callbackFn), 300);
  }

  handleSearch(query, callbackFn) {
    if (!this.fetchOnSearch) return;

    if (query.length < 3) {
      const placeholder = this.placeholderOption || "Zadejte hledaný výraz\u2026"
      callbackFn(placeholder)
      return
    }

    const url = new URL(this.url)
    const { origin, pathname, search } = url

    const parsed = qs.parse(search, { ignoreQueryPrefix: true })
    const { q } = parsed
    const existing = q == null ? parsed : q

    const deps = safeParse(this.queryDependencies) || {}
    let filters = Object.keys(deps).reduce((memo, key) => {
      const selector = `[data-dependency-id~=${deps[key]}]`
      const raw = document.querySelector(`${selector}`)
      let element
      let value

      switch (raw.type) {
        case "radio":
        case "checkbox":
          element = document.querySelector(`${selector}:checked`)
          value = element.dataset.value || element.value
          break;
        default:
          element = document.querySelector(`${selector}`)
          value = element.dataset.value || element.value
      }

      if (element && value !== undefined) memo[key] = value
      return memo
    }, {})

    filters = Object.assign(filters, {
      [this.queryFilterName]: query
    })

    const path = `${origin}${pathname}`

    rFetch(path, {
      as: "json",
      contentType: "application/json",
      query: {
        q: { ...filters, ...existing },
      },
    })
    .then((res) => {
      const data = res.map((el) => {
        return Object.assign(
          {},
          {
            text: el[this.labelOption],
            value: el[this.valueOption],
          }
        );
      });

      return data.length > 0 ? callbackFn(data) : callbackFn("Nic nenalezeno\u2026")
    })
    .catch(false)
  }

  calculateDropdownPosition = () => {
    const select = this.element.slim;
    if (select == null) return;

    const { container, content } = select.slim;
    if (container.closest("th") == null) return;

    const clientRect = container.getBoundingClientRect();
    content.style.left = `${clientRect.left}px`;
  };

  maybeAddSelectOptions = (info = null) => {
    if (this.element.type === "select-one") return false;
    if (this.element.dataset.preventAddSelectOptions) return false;

    const select = this.element.slim;
    const current = select.data.data;

    const selectedSize = info ? info.length : select.selected().length;
    const hasSelectAll =
      current.filter((v) => v.value === "Přidat vše\u2026").length > 0;
    const hasDeselectAll =
      current.filter((v) => v.value === "Odstranit vše\u2026").length > 0;

    const applySelectAll = select.selected().includes("Přidat vše\u2026");
    const applyDeselectAll = select.selected().includes("Odstranit vše\u2026");
    const data = current.filter(
      (v) => !["Přidat vše\u2026", "Odstranit vše\u2026"].includes(v.value)
    );

    if (applySelectAll) {
      select.set(data.map((v) => v.value));
      select.setData(data);
      return;
    }

    if (applyDeselectAll) {
      select.set([]);
      select.setData(data);
      return;
    }

    if (selectedSize && !hasDeselectAll) {
      current.unshift({ value: "", text: "Odstranit vše\u2026" });
    }

    if (selectedSize !== data.length && !hasSelectAll) {
      current.unshift({ value: "", text: "Přidat vše\u2026" });
    }

    select.setData(current);
  };

  populateOptions = (result) => {
    const input = this.select

    if (!input) return;
    const data = result.map((el) => {
      return Object.assign(
        {},
        {
          text: el[this.labelOption],
          value: el[this.valueOption],
        }
      );
    });

    input.setData(data);
    input.set([this.selected]);
  };

  get fetchOnConnect() {
    return this.data.get("fetch-on") === "connect"
  }

  get fetchOnSearch() {
    return this.data.get("fetch-on") === "search"
  }

  get queryDependencies() {
    return this.data.get("query-dependencies")
  }

  get queryFilterName() {
    return this.data.get("query-filter-name")
  }

  get selected() {
    return this.data.get("selected")
  }

  get placeholderOption() {
    return this.data.get("placeholder")
  }

  get labelOption() {
    return this.data.get("label")
  }

  get valueOption() {
    return this.data.get("value")
  }

  get url() {
    return this.data.get("url")
  }
}
