import { Controller } from "stimulus";
import { animateElementWithClass, removeElement } from "../src/utils";

export default class extends Controller {
  static timeout;

  initialize() {
    this.timeout = setTimeout(() => {
      this.animateAndRemove(this.element);
    }, this.duration);
  }

  disconnect() {
    this.timeout = null;
  }

  close() {
    clearTimeout(this.timeout);
    this.animateAndRemove(this.element);
  }

  animateAndRemove(elem) {
    animateElementWithClass(elem, ["animated", "scaleFadeOut"], () => {
      removeElement(elem);
    });
  }

  get duration() {
    return parseInt(this.data.get("duration")) * 1000;
  }
}
