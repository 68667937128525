import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["yearInput"];

  select(event) {
    event.preventDefault();

    if (event.target && event.target.value === 0) return;

    const selected = flatpickr.parseDate(event.target.value, this.dateFormat);

    if (this.yearInputTarget && this.yearInputTarget.value.length === 0) {
      this.yearInputTarget.value = this.getYearForInput(selected);
    }
  }

  getYearForInput(date) {
    if (date.getFullYear() > this.currentYear) {
      return date.getFullYear();
    } else {
      return this.currentYear;
    }
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get dateFormat() {
    return "d.m.Y";
  }
}
