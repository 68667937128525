import { Controller } from "stimulus"
import { rFetch } from "src/utils";

export default class extends Controller {
  static targets = ["entries", "pagination"]

  initialize() {
    let options = {
      rootMargin: "200px",
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.observable)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.observable)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href

    rFetch(url, {
      as: "json",
      contentType: "application/json",
    })
    .then((data) => {
      if (this.isSticky) this.intersectionObserver.unobserve(this.observable)

      this.entriesTarget.insertAdjacentHTML("beforeend", data.entries)
      this.paginationTarget.innerHTML = data.pagination

      if (this.isSticky) this.intersectionObserver.observe(this.observable)
    })
  }

  get shouldShowPlaceholderImage() {
    return !processedImageURLs.has(this.element.src)
  }

  get observable() {
    return this.isSticky ?
      this.entriesTarget.querySelector("tr:last-of-type") :
      this.paginationTarget
  }

  get isSticky() {
    return this.data.get("sticky") === "true";
  }
}
