import { Controller } from "stimulus";
import { initQuintessentialDnD } from "src/drag_n_drop";

export default class extends Controller {
  connect() {
    initQuintessentialDnD({
      itemSelector: this.itemSelector,
      listSelector: this.listSelector,
      param: this.param,
      url: this.url
    });
  }

  get itemSelector() {
    return this.data.get("item");
  }

  get listSelector() {
    return this.data.get("list");
  }

  get param() {
    return this.data.get("param");
  }

  get url() {
    return this.data.get("url");
  }
}
