import { Controller } from "stimulus";

const ACTIONS = "initMerge completeMerge".split(" ")

export default class extends Controller {
  static targets = [
    "checkbox", "selectedIdsField", ...ACTIONS.map(action => `${action}Button`)
  ]

  connect() {
    this.enableButtons()
  }

  update(event) {
    if (event?.target.checked) {
      if (this.isShiftSelected && this.lastSelectedCheckbox) {
        this.shiftSelect(this.lastSelectedCheckbox, event.target)
      }
      this.lastSelectedCheckbox = event.target
    }

    const { ids, selectedCount } = this.selected
    this.selectedIdsFieldTargets.forEach(field => field.value = ids)
    this.enableButtons()
  }

  async reset(event) {
    if (event?.type == "submit") await nextFrame()
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
    this.update()
  }

  enableShiftSelect(event) {
    if (event.key == "Shift") { this.shiftSelectEnabled = true }
  }

  disableShiftSelect(event) {
    if (event.key == "Shift") { this.shiftSelectEnabled = false }
  }

  shiftSelect(startTarget, endTarget) {
    const start = this.checkboxTargets.indexOf(startTarget)
    const end = this.checkboxTargets.indexOf(endTarget)
    this.checkboxTargets.slice(Math.min(start, end), Math.max(start, end) + 1).forEach(checkbox => checkbox.checked = true)
  }

  // Private

  get selected() {
    const result = { ids: [], selectedCount: 0 }

    this.selectedCheckboxTargets.forEach(({ dataset }) => {
      result.ids.push(dataset.identifier)
      result.selectedCount++
    })

    return result
  }

  get selectedCheckboxTargets() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get isShiftSelected() {
    return this.shiftSelectEnabled
  }

  get enabledMenuItems() {
    return this.menuItemTargets.filter(target => !target.hidden)
  }

  enableButtons() {
    const { selectedCount } = this.selected

    ACTIONS.forEach(kind => {
      if (this[`has${this.capitalizeString(kind)}ButtonTarget`]) {
        this[`${kind}ButtonTargets`].forEach(target => {
          target.hidden = selectedCount === 0
        })
      }
    })
  }

  capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}
