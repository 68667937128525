import { Controller } from "stimulus";

export default class extends Controller {
  toggleAttributes(event) {
    event.preventDefault();

    this.toggled = !this.toggled
  }


  get toggled() {
    return this.data.has("toggled") && this.data.get("toggled") == "true";
  }

  set toggled(value) {
    return this.data.set("toggled", value);
  }

}
