import qs from "qs";

export const onDocumentReady = (callback) => {
  if (document.readyState !== "loading") {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
};

export const findElement = (root, selector) => {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
};

export const findElements = (root, selector) => {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  const elements = root.querySelectorAll(selector);
  return toArray(elements);
};

export const makeElement = (tag, opts = {}) => {
  let element = document.createElement(tag);

  for (const attr in opts) {
    const value = opts[attr];
    element.setAttribute(attr, value);
  }

  return element;
};

export const removeElement = (el) => {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function getAuthenticityToken() {
  return getMetaValue("csrf-token");
}

export const dispatchEvent = (element, type, initEvent = {}) => {
  const { disabled } = element;
  const { bubbles, cancelable, detail } = initEvent;
  const event = document.createEvent("Event");

  event.initEvent(type, bubbles || true, cancelable || true);
  event.detail = detail || {};

  try {
    element.disabled = false;
    element.dispatchEvent(event);
  } finally {
    element.disabled = disabled;
  }

  return event;
};

export const toArray = (value) => {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
};

export const insertAfter = (el, ref) => {
  return ref.parentNode.insertBefore(el, ref.nextSibling);
};

export const animateElementWithClass = (el, tokens, callback) => {
  if (Array.isArray(tokens)) {
    DOMTokenList.prototype.add.apply(el.classList, tokens);
  } else {
    el.classList.add(className);
  }

  const handleAnimateEnd = () => {
    if (Array.isArray(tokens)) {
      DOMTokenList.prototype.remove.apply(el.classList, tokens);
    } else {
      el.classList.remove(className);
    }
    el.removeEventListener("animationend", handleAnimateEnd);

    if (typeof callback === "function") callback();
  };

  el.addEventListener("animationend", handleAnimateEnd());
};

export const rFetch = (resource, _options = {}) => {
  const { query, ...options } = _options;
  let url = resource;

  setupHeaders();
  setupContentType();
  setupAccept();
  setupCredentials();
  setupQueryParams();

  return fetch(url, options).then(checkStatus).then(parseResponse);

  function setupHeaders() {
    const headers = Object.assign({}, options.headers);
    headers["X-CSRF-Token"] = getAuthenticityToken();
    headers["X-Requested-With"] = "XMLHttpRequest";
    options.headers = headers;
  }

  function setupContentType() {
    if (options.contentType)
      options.headers["Content-Type"] = options.contentType;
  }

  function setupAccept() {
    if (options.as == "json") {
      options.headers["Accept"] = "application/json";
    }
    if (options.as == "xhr") {
      options.headers["Accept"] = "text/javascript";
    }
    if (options.as == "html") {
      options.headers["Accept"] = "text/html";
    }
  }

  function setupCredentials() {
    options.credentials = options.credentials || "same-origin";
  }

  function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  function setupQueryParams() {
    const method = options.method || "GET";

    if (["GET", "DELETE"].indexOf(method) > -1) {
      url = query ? `${url}?${qs.stringify({ ...query })}` : resource;
    } else {
      if (query && options.body == null) {
        options.body = qs.stringify(query);
      }
    }
  }

  function parseResponse(response) {
    switch (options.as) {
      case "blob":
        return response.blob();
      case "json":
        return response.json();
      default:
        return response.text();
    }
  }
};

export const getPlaceholderImageURL = (r = 0, g = 0, b = 0, a = 0) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const size = (canvas.width = canvas.height = 1);
  context.fillStyle = `rgba(${r}, ${g}, ${b}, ${a})`;
  context.fillRect(0, 0, size, size);

  return canvas.toDataURL("image/png");
};

export const refreshWithScrollLock = () => {
  let scrollPosition;

  document.addEventListener(
    "turbolinks:load",
    () => {
      if (scrollPosition) {
        window.scrollTo.apply(window, scrollPosition);
        scrollPosition = null;
      }
    },
    false
  );

  scrollPosition = [window.scrollX, window.scrollY];
  Turbolinks.visit(window.location, { action: "replace" });
};

export const isMobile = () => {
  return window.matchMedia("only screen and (max-width: 760px)").matches;
};

export const safeParse = (data) => {
  try {
    return JSON.parse(data);
  } catch (_) {
    return null;
  }
};
