import { Controller } from "stimulus";
import { isMobile, makeElement, rFetch } from "src/utils";

export default class extends Controller {
  static targets = ["modal"];

  initialize() {
    if (!this.modalContainer) this.initializeContainer();
  }

  connect() {
    this.resetModal();
  }

  openModal(event) {
    event.preventDefault();

    const type = isMobile() ? "full" : "box";
    this.modalTarget.setAttribute("type", type);
    const eventType = event?.type.split(":")

    if (eventType && eventType[0] === "ajax") {
      this.handleResponse(event, (fragment) => {
        this.render(fragment)
      })
    } else {
      const { currentTarget: { dataset: { url } } } = event;
      this.loadUrl(url);
    }

    this.modalTarget.open();
  }

  closeModal(event) {
    event.preventDefault();

    this.modalTarget.close();
    this.resetModal();
  }

  closeWithKeyboard(event) {
    if (event.keyCode === 27) this.closeModal(event);
  }

  resetModal() {
    this.modalTarget.innerHTML = "";
    this.addLoader();
    this.setLoaded(false);
  }

  // Private

  loadUrl(url) {
    rFetch(url, { as: "xhr", contentType: "application/javascript" }).then(
      this.render.bind(this)
    );
  }

  render(html) {
    this.removeLoader();
    this.modalTarget.innerHTML = ""
    this.modalTarget.innerHTML = html;
    this.setLoaded(true);
  }

  addLoader() {
    this.modalTarget.classList.add("loading");
  }

  removeLoader() {
    this.modalTarget.classList.remove("loading");
  }

  setLoaded(value) {
    this.modalTarget.setAttribute("loaded", value);
  }

  initializeContainer() {
    let element = makeElement("app-modal", {});
    element.classList.add("loadable", "loading");
    document.body.appendChild(element);
  }

  get modalContainer() {
    return document.getElementById("app-modal");
  }

  handleResponse(event, callback) {
    const xhr = event.detail[2]
    callback(xhr.response)
  }
}
