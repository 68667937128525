
import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import { Czech } from "flatpickr/dist/l10n/cs.js";
import { removeElement } from "../src/utils";

export default class extends Controller {
  static targets = ["input"];
  static datepickr;

  connect() {
    this.datepickr = flatpickr(this.element, this.options)
    this.initClearButton(this.element)
  }

  disconnect() {
    this.datepickr.destroy();
    this.datepickr = null;
  }

  handleChange(event) {
    const target = event.currentTarget
    this.initClearButton(target)
  }

  initClearButton(element) {
    const value = element.value
    const parent = element.parentElement
    const button = parent.querySelector("[data-target~=datepickr-clear-button]");

    if (value === "") {
      if (button == null) return;

      button.removeEventListener("click", this.clearInput);
      removeElement(button);
    } else {
      if (button == null) {
        element.insertAdjacentHTML("afterend", this.buttonHtml);

        const initializedButton = parent.querySelector("[data-target~=datepickr-clear-button]");
        initializedButton.addEventListener("click", this.clearInput.bind(this));
      }
    }
  }

  clearInput(event) {
    this.datepickr.clear()
  }

  get buttonHtml() {
    return `
      <span
        class="flex align-center justify-end flex-initial cursor-pointer font-bold absolute w-6 h-6 right-2 bottom-2"
        data-target="datepickr-clear-button"
      >
        <span class="text-xs">✖</span>
      </span>
    `.trim()
  }

  get options() {
    switch (this.type) {
      case "time":
        return Object.assign({}, {
          allowInput: true,
          altFormat: "H:i",
          altInput: true,
          dateFormat: "H:i",
          enableSeconds: false,
          enableTime: true,
          noCalendar: true,
          time_24hr: true,
          locale: Czech
        });
        break;
      case "date":
        return Object.assign({}, {
          enableTime: false,
          allowInput: true,
          altInput: true,
          altFormat: "d.m.Y",
          dateFormat: "d.m.Y",
          locale: Czech
        });
        break;
      case "datetime":
        return Object.assign({}, {
          enableTime: true,
          altInput: true,
          altFormat: "d.m.Y H:i",
          dateFormat: "d.m.Y H:i",
          time_24hr: true,
          locale: Czech
        });
        break;
      default:
        break;
    }
  }

  get type() {
    return this.data.get("type");
  }
}
