import { Controller } from "stimulus";
import { rFetch } from "src/utils";

const fetchCollection = (url, callbackFn, search = {}) => {
  rFetch(url, {
    as: "json",
    contentType: "application/json",
    query: {
      q: search,
    },
  }).then(callbackFn);
};

export default class extends Controller {
  static targets = ["form", "breedingUntilInput", "currentlyBreedingInput", "dateOfBirthInput"];

  fetchAssociations = (event) => {
    const breed = this.normalizedBreed(event.target.value);

    if (this.shouldFetchBreedCentres) this.fetchBreedCentres(breed);
    if (this.shouldFetchParents) this.fetchParents(breed);
    if (this.shouldFetchExams) this.fetchExams(breed);
    if (this.shouldFetchTrophies) this.fetchTrophies(breed);
  };

  fillBreedingDate = (event) => {
    const currentlyBreeding = this.currentlyBreedingInputTarget && this.currentlyBreedingInputTarget.checked === true
    const dateOfBirth = this.dateOfBirthInputTarget && this.dateOfBirthInputTarget.value

    if (!dateOfBirth) return;

    const breedingUntil = this.breedingUntilInputTarget
    const datePicker = breedingUntil._flatpickr

    let value

    if (currentlyBreeding) {
      const parsed = flatpickr.parseDate(dateOfBirth, "d.m.Y")
      value = new Date(parsed.setFullYear(parsed.getFullYear() + 8));
    } else {
      value = null
    }

    datePicker.setDate(value, true, "d.m.Y")
  };

  fetchBreedCentres(breed) {
    fetchCollection(this.breedCentresUrl, this.replaceBreedCentresOptions, {
      breed_eq: breed,
    });
  }

  fetchParents(breed) {
    fetchCollection(this.parentsUrl, this.replaceParentsOptions, {
      breed_eq: breed,
    });
  }

  fetchExams(breed) {
    fetchCollection(this.examsUrl, this.replaceExamsOptions, {
      breed_eq: breed,
    });
  }

  fetchTrophies(breed) {
    fetchCollection(this.trophiesUrl, this.replaceTrophiesOptions, {
      breed_eq: breed,
    });
  }

  replaceBreedCentresOptions = (result) => {
    const raw = this.element.querySelector(`[data-target~=breed-centre-input]`);
    const select = raw ? raw.slim : null;

    this.replaceOptions(select, result, "id", "name");
  };

  replaceParentsOptions = (result) => {
    ["male", "female"].forEach((sex) => {
      const raw = this.element.querySelector(
        `[data-target~=hound-parent-${sex}-input]`
      );

      const select = raw ? raw.slim : null;
      const data = result.filter((el) => el.sex === sex);

      this.replaceOptions(select, data, "id", "formatted_name");
    });
  };

  replaceExamsOptions = (result) => {
    const raw = this.element.querySelector(`[data-target~=exam-input]`);
    const select = raw ? raw.slim : null;

    this.replaceOptions(select, result, "abbreviation", "name");
  };

  replaceTrophiesOptions = (result) => {
    const selectors = this.element.querySelectorAll(`[data-target~=trophy-input]`);

    selectors.forEach(selector => {
      const select = selector ? selector.slim : null;

      this.replaceOptions(select, result, "id", "name");
    })
  };

  replaceOptions(select, options, value_key, text_key) {
    if (!select) return;
    const data = options.map((el) => {
      return Object.assign(
        {},
        {
          text: el[text_key],
          value: el[value_key],
        }
      );
    });

    if (select) {
      select.setData(data);
      select.set([]);
    }
  }

  get shouldFetchBreedCentres() {
    const selector = this.element.querySelector(
      `[data-target~=breed-centre-input]`
    );
    return Boolean(selector);
  }

  get shouldFetchParents() {
    return ["male", "female"].some((sex) => {
      const selector = this.element.querySelector(
        `[data-target~=hound-parent-${sex}-input]`
      );

      return Boolean(selector);
    });
  }

  get shouldFetchExams() {
    const selector = this.element.querySelector(
      `[data-target~=exam-input]`
    );
    return Boolean(selector);
  }

  get shouldFetchTrophies() {
    const selector = this.element.querySelector(
      `[data-target~=trophy-input]`
    );
    return Boolean(selector);
  }

  normalizedBreed(breed) {
    return this.breeds[breed];
  }

  get breeds() {
    return Object.assign(
      {},
      {
        bavarian_hound: 0,
        hanover_hound: 1,
      }
    );
  }

  get breedCentresUrl() {
    return this.data.get("breedCentresUrl");
  }

  get parentsUrl() {
    return this.data.get("parentsUrl");
  }

  get examsUrl() {
    return this.data.get("examsUrl");
  }

  get trophiesUrl() {
    return this.data.get("trophiesUrl");
  }
}
