import { rFetch } from "src/utils";

class MapGeometry {
  constructor(element, dataSource, map, options) {
    this.element = element
    this.options = options || {}
    this.map = map
    this.dataSource = dataSource

    fetchDataSource(this, dataSource)
  }

  render() {
    this.processData();
    renderGeometry(this.geometryName(), this);
  }

  center() {
    if (this.coordinates.length > 0) {
      const computed = this.map.computeCenterZoom(this.coordinates)
      const [center, zoom] = computed
      this.map.setCenterZoom(center, zoom)
    }
  }
}

class MarkerGeometry extends MapGeometry {
  processData() {
    const [coordinates, data] = processMarkers(this)

    this.data = data
    this.coordinates = (this.coordinates || []).concat(coordinates)

    const layer = new SMap.Layer.Marker(this.element)

    if (this.options["cluster"] === true) {
      const clusterer = this.configClusterer()
      layer.setClusterer(clusterer)
    }

    layer.addMarker(this.data)
    this.map.addLayer(layer).enable()
  }

  geometryName() {
    return "MarkerGeometry";
  }

  configClusterer() {
    const CustomClusterer = JAK.ClassMaker.makeClass({
      NAME: "CustomClusterer",
      VERSION: "1.0",
      EXTEND: SMap.Marker.Cluster
    })

    CustomClusterer.prototype.click = function(e, elm) {
      const zoom = 18;
      const m = this.getMap();

      if (m.getZoom() >= zoom) {
        const card = new SMap.Card()
        let panel = ""

        for (i = 0; i < this._markers.length; i++) {
          panel += this._markers[i]._card.getBody().innerHTML
        }

        card.getContainer().classList.add("map-card")
        card.getBody().innerHTML = panel
        m.addCard(card, this.getCoords())
      } else {
        this.$super(e, elm);
      }
    }

    new SMap.Marker.Clusterer(this.map, 25, CustomClusterer);
  }
}

const Mapkick = {
  MarkerGeometry: MarkerGeometry,
  geometries: {},
};

if (typeof window !== "undefined" && !window.Mapkick) {
  window.Mapkick = Mapkick;
}

Mapkick.default = Mapkick;
export default Mapkick;

const fetchDataSource = (instance, dataSource) => {
  if (typeof dataSource === "string") {
    rFetch(dataSource, {
      as: "json",
      contentType: "application/json"
    })
    .then(data => {
      instance.rawData = data
      errorCatcher(instance)
    })
    .catch(err => errorToConsole(err.message))
  } else if (typeof dataSource === "function") {
    try {
      dataSource((data) => {
        instance.rawData = data;
        errorCatcher(instance);
      }, (message) => errorToConsole(message))
    } catch (err) {
      errorToConsole(err.message);
    }
  } else {
    instance.rawData = dataSource
    errorCatcher(instance)
  }
}

const renderGeometry = (geometryType, instance) => {
  instance.center()
}

const processMarkers = (instance) => {
  let markers = []
  let coordinates = []

  instance.rawData.forEach(item => {
    const { latitude, longitude, popup, icon } = item
    const url = icon || instance.options["icon"]
    const coords = SMap.Coords.fromWGS84(longitude, latitude);

    coordinates = coordinates.concat(coords);
    const marker = new SMap.Marker(coords, null, { url });

    if (popup) {
      const card = new SMap.Card();
      card.getContainer().classList.add("map-card")
      card.getBody().innerHTML = popup;
      marker.decorate(SMap.Marker.Feature.Card, card);
    }

    markers.push(marker)
  })

  return [coordinates, markers]
}

const errorCatcher = (instance) => {
  try {
    instance.render();
  } catch (err) {
    errorToConsole(err.message);
    throw err;
  }
}

const errorToConsole = (value) => {
  const message = `🚨 %c[Mapkick Error]: ${value}`
  console.log(message, "color: #ff0000; font-weight:bold;")
}
